@import '../../color_variables.scss';
@import '../../size_variables.scss';


.training-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 6em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 5em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 4em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3.5em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__wrapper {
    max-width: 90em;
    flex: 1;
  }

  &__indicators {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__graph {
      flex: 1;
      background: white; //#e8e8e8
      border-radius: 0 5px 0 5px;
      height: 15em;
      min-height: 15em;

      @media (max-width: $medium-width-breakpoint) {
        height: 14em;
        min-height: 14em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 12em;
        min-height: 12em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        height: 11em;
        min-height: 11em;
        border-radius: 0;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        height: 10em;
        min-height: 10em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        height: 8em;
        min-height: 8em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        height: 7.5em;
        min-height: 7.5em;
      }
    }

    &__result {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex-shrink: 0;
      padding: 0.5em 2em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.92em;
        padding: 0.5em 1em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.88em;
        padding: 0.2em 0.8em;
      }

      @media (max-width: $small-width-breakpoint) {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.8em 0.4em;
      }

      &__label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 1.5em;
        align-self: stretch;
        border: 0 solid white;
        border-bottom-width: 2px;
        padding: 0 0 0.5em;

        @media (max-width: $small-width-breakpoint) {
          border-bottom-width: 0;
          border-right-width: 1px;
          padding: 0 0.5em 0 0;
        }

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.3em;
          padding: 0 0.4em 0 0;
        }

        @media (max-height: $small-height-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          display: none;
        }
      }

      &__value-wrapper {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.5em 0;

        @media (max-width: $small-width-breakpoint) {
          flex-flow: row nowrap;
          margin: 0 0 0 1em;
          padding: 0;
        }

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0 0.6em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0;
        }
      }

      &__description {
        font-family: 'Montserrat', sans-serif;
        color: white;
        max-width: 23em;
        text-align: justify;
        font-size: 0.9em;
        margin: 0.8em 0 0;

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 19em;
        }

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 1em;
          max-width: none;
        }

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0 0.8em;
          font-size: 0.85em;
        }

        @media (max-height: $small-height-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0 0.8em;
          font-size: 0.82em;
        }
      }

      &__value {
        &--alert,
        &--normal,
        &--danger,
        &--extreme-danger {
          font-family: 'Montserrat', sans-serif;
          color: white;
          font-size: 1.3em;
          font-weight: bold;
          padding: 0.5em 0.8em;
          border-radius: 5px;

          @media (max-width: $smaller-width-breakpoint) {
            border-radius: 3px;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
            padding: 0.3em 0.4em;
            border-radius: 3px;
            font-size: 1.2em;
          }

          @media (max-width: $very-small-width-breakpoint) {
            font-size: 1.1em;
          }
        }

        &--alert {
          background: #fdcd01;
        }
        &--normal {
          background: #029642;
        }
        &--danger {
          background: #e82310;
        }
        &--extreme-danger {
          background: #bc0100;
        }
      }
    }
  }

  &__indicators-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    border-radius: 0 0 5px 5px;

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
    }

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 0 0 3px 3px;
    }
  }

  &__action-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 1.2em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0.7em 0;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 0.6em 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0.5em 0;
    }
  }

  &__action {
    font-size: 1.8em;
    border-radius: 3px;
    box-shadow: none;
    padding: 0.3em 0.6em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.7em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.4em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.2em;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__recent-trainings {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    margin: 0 0 2em;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.3em 0.2em;
      }
    }

    &__no-entry-message {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: center;
      font-size: 1.2em;
    }

    &__entry {
      &,
      &--personal {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        font-family: 'Montserrat', sans-serif;
        border: 1px solid $default-black-color;
        border-radius: 3px;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 0.88em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.8em;
        }

        &:not(:first-child) {
          margin: 0.5em 0 0;
        }
      }

      &__date {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.3em;
        background: $default-black-color;
        border-radius: 3px 0 0 3px;
        color: white;
        padding: 0 0.5em;
        margin: 0 0.4em 0 0;
        width: 7em;
        flex-shrink: 0;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          padding: 0;
          width: 6.5em;
          font-size: 1.15em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.9em;
          width: 3.8em;
        }
      }

      &--personal &__date {
        background: linear-gradient(190deg, #d2ba65, #ab954a);
      }

      &__title-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
      }

      &__title {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2em;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.1em;
        }
      }

      &__notification {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8em;
        color: $site-red-color;
        text-transform: uppercase;
        font-weight: bold;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.7em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
      }

      &__action {
        font-size: 1.4em;
        border-radius: 3px;
        box-shadow: none;
        padding: 0.4em;
        flex: none;
        margin: 0.2em 0;
        width: 2em;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.3em;
        }

        &:not(:first-child) {
          margin-left: 0.5em;

          @media (max-width: $smaller-width-breakpoint) {
            margin-left: 0.4em;
          }
        }

        &:last-child {
          margin-right: 0.2em;
        }
      }
    }
  }

  &__note {
    margin: 0.5em 0 0;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }
}
