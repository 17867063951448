$background-color: #e8e8e8;
$default-black-color: #3a3839;

$site-background-color: #364150;
$site-red-color: #e83f53;

$default-green-color: #57904f;
$default-gray-color: #9e9fa1;

$default-text-color: #333;

$default-alert-color: #f0ad4e;
