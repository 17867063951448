@import '../color_variables.scss';
@import '../size_variables.scss';

.personal-training-report {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__data {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;
    border: 1px solid $default-black-color;
    border-top-width: 0;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }
  }

  &__graph {
    min-width: 500px;
  }

  &__graph-section {
    color: $default-black-color;
    box-shadow: 0px 2px 0px $default-black-color;
    padding: 0 0 0.1em 0;
    margin: 0 0 0.8em;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &:not(:first-of-type) {
      margin-top: 1em;
    }

    &__text {
      text-transform: uppercase;
      font-weight: bolder;

      &:not(:first-child) {
        margin: 0 0 0 0.4em;
      }
    }
  }
}
