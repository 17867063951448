@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';


$alert-color: #dec913;
$error-color: #d43f3a;
$success-color: #72bf33;
$loading-color: #5f5f5f;
$warning-color: #ea5460;
$light-text-color: #777;

@keyframes SPIN_ANIMATION {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes PULSE_ANIMATION {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}


.confirmation-window {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 3;
  will-change: transform;
  top: 0;
  left: 0;

  @media (max-width: $smaller-width-breakpoint) {
    align-items: stretch;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    background: white;
    padding: 0.6em 1.5em 0.8em;
    margin: 0.4em;
    border-radius: 8px;
    box-shadow: 3px 3px 3px #65656580;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-height: $small-height-breakpoint) and (min-height: $small-height-breakpoint) {
      margin: 4em 0 0;
    }

    @media (max-width: $smaller-width-breakpoint) {
      // margin: 6.2em 0.4em 0;
      padding: 0.8em 0.5em;
    }
  }

  &__icon-wrapper {
    margin: 0.5em 0 1em;
    // background: radial-gradient(#5a5a5a, #656565 61%, transparent 68%, transparent);
    padding: 0.5em;
  }

  &__alert-icon {
    font-size: 6em;
    background-color: $alert-color;
    color: transparent;
    text-shadow: -2px -2px 3px rgba(255, 255, 255, 0.38);
    background-clip: text;
  }

  &__error-icon {
    position: relative;
    font-size: 6em;
    background-color: $error-color;
    color: transparent;
    text-shadow: -2px -2px 5px rgba(255, 255, 255, 0.31);
    background-clip: text;
  }

  &__success-icon {
    position: relative;
    font-size: 6em;
    background-color: $success-color;
    color: transparent;
    text-shadow: -2px -2px 5px rgba(255, 255, 255, 0.31);
    background-clip: text;
  }

  &__alert-icon,
  &__success-icon,
  &__error-icon {
    @media (max-width: $small-width-breakpoint) {
      font-size: 5em;
    }
  }

  &__loading-icon {
    font-size: 3.5em;
    color: $loading-color;
    animation: SPIN_ANIMATION 3s linear infinite;
    color: transparent;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.47);
    background-color: $default-black-color;
    background-clip: text;

    @media (max-width: $small-width-breakpoint) {
      font-size: 3em;
    }
  }

  &__title--pulsing,
  &__title {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    font-weight: lighter;
    margin: 0 0 0.6em;
  }

  &__title--pulsing {
    animation: PULSE_ANIMATION 1s ease-in-out infinite alternate;
  }

  &__description {
    font-family: 'Montserrat', sans-serif;
    color: $light-text-color;
    text-align: center;
    min-width: 25em;
    max-width: 35em;
    font-weight: lighter;
    margin: 0 0 1.5em;
    font-size: 0.95em;

    @media (max-width: $very-small-width-breakpoint) {
      min-width: auto;
    }
  }

  &__controls-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    padding: 0.5em;
  }

  &__cancel-button,
  &__confirm-button {
    font-size: 1.5em;
    border-radius: 0.1em;
    padding: 0.5em;

    &:not(:last-of-type) {
      margin: 0 2em 0 0;
    }
  }
}
