@import '../color_variables.scss';
@import '../size_variables.scss';

$intermediate-width-breakpoint: 460px;

%default-padding {
  padding: 0 30px;

  @media (max-width: $intermediate-width-breakpoint) {
    padding: 0 15px;
  }
}

.forgot-password {
  background: $site-background-color;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;

  @media (max-height: $medium-height-breakpoint) {
    justify-content: center;
  }

  &__container {
    margin: 17vh 0 0;
    background: white;
    width: 25em;

    @media (max-width: $intermediate-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      align-self: center;
      margin-right: 10px;
      margin-left: 10px;
      width: auto;
      max-width: 480px;
    }

    @media (max-height: $medium-height-breakpoint) {
      margin-top: 0;
    }
  }

  &__header {
    @extend %default-padding;
    // font-family: 'Black Ops One', cursive;
    font-family: 'Black Ops One', fantasy;
    width: 100%;
    color: #555;
    margin: 25px 0;

    @media (max-width: $tiny-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin-bottom: 10px;
    }

    @media (max-height: $small-height-breakpoint) {
      margin-top: 6px;
      margin-bottom: 4px;
    }

    &__text {
      font-size: 1.65em;
      text-align: center;

      @media (max-width: $intermediate-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        font-size: 1.3em;
      }

      &--red {
        color: $site-red-color;
      }
    }
  }

  &__title {
    @extend %default-padding;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    margin: 0 0 10px;
    color: #555;

    @media (max-width: $intermediate-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      font-size: 0.8em;
      margin-bottom: 6px;
    }
  }

  &__message {
    @extend %default-padding;
    text-align: justify;
    margin: 0 0 30px;
    color: #555;
    font-size: 1.1em;
    font-weight: bold;

    @media (max-width: $intermediate-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      font-size: 0.9em;
    }

    &__text--red {
      color: $site-red-color;
    }
  }

  &__warning-wrapper {
    @extend %default-padding;
    margin: 0 0 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-height: $small-height-breakpoint) {
      margin-bottom: 8px;
    }
  }

  &__input-wrapper {
    @extend %default-padding;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;
  }

  &__input {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    background: #dde3ec;
    color: #8290a3;
    padding: 10px 12px;
    border: none;
    outline: none;
    border: 1px solid #dde3ec;

    @media (max-height: $tiny-height-breakpoint) {
      padding: 8px 12px;
    }

    @media (max-height: $tiny-height-breakpoint) {
      padding: 6px 12px;
      font-size: 0.95em;
    }

    &:focus {
      border: 1px solid #c2cad8;
    }

    @media (max-width: $intermediate-width-breakpoint) {
      font-size: 0.9em;
    }
  }

  &__request-wrapper {
    @extend %default-padding;
    margin: 30px 0 25px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: $tiny-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media (max-height: $small-height-breakpoint) {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  &__request-button {
    padding: 15px 0;
    color: #333;
    border: none;
    outline: none;
    user-select: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(to top,#dadada,#fafafa);
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;

    &:disabled {
      color: #949494;
      background: #ddd;
      cursor: default;
    }

    @media (max-height: $tiny-height-breakpoint) {
      padding: 8px 0;
      font-size: 0.78em;
    }
  }

  &__footer {
    @extend %default-padding;
    background: #6c7a8d;
    cursor: default;

    &__text {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 0.85em;
      color: #c3cedd;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.76em;
      }

      @media (max-height: $small-height-breakpoint) {
        padding: 8px 0;
        font-size: 0.75em;
      }
    }
  }

  &__main-link {
    text-decoration: none;
    cursor: pointer;
  }
}

.horizontal-rule {
  width: 100%;
  border: 0 solid #eee;
  border-width: 1px 0 0;
  margin: 1em 0;

  @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
    margin: 0.8em 0;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    margin: 0.4em 0;
  }
}
