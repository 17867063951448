@import '../constants.scss';
@import '../size_variables.scss';

$default-dark-blue-color: #2c3e50;
$default-text-color: #333;
$default-light-gray: #d4d4d4;

$action-button-light-gray-color: #ccc;
$action-button-gray-color: #717171;

%default-side-margin {
  margin: 0 1.1em 1.1em;

  @media (max-width: $small-width-breakpoint) {
    margin: 0 0.6em 0.6em;
  }
}

%default-side-padding {
  padding-left: 0.9em;
  padding-right: 0.9em;

  @media (max-width: $small-width-breakpoint) {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}

.default-section {
  @extend %default-side-margin;
  border: 1px solid $default-dark-blue-color;
  align-self: stretch;

  &__header {
    @extend %default-side-padding;
    background: $default-dark-blue-color;
    color: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.92em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      text-align: center;
      font-size: 0.72em;
    }

    &__text {

    }
  }

  &__content-wrapper {
    @extend %default-side-padding;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding-top: 0.9em;
    padding-bottom: 0.9em;

    @media (max-height: $medium-height-breakpoint) {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }

    @media (max-width: $small-width-breakpoint) {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }
  }

  &__links-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-end;
    margin: 0 0 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__default-link-button {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    padding: 0.8em;
    font-size: 0.8em;
    min-width: 35px;
    min-height: 35px;
    transition: background $default-transition-duration;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    text-decoration: none;
    background: $action-button-light-gray-color;
    color: $action-button-gray-color;

    &:hover {
      background: darken($action-button-light-gray-color, 15);
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }
}


.horizontal-rule {
  &,
  &--black {
    width: 100%;
    border: 0 solid #eee;
    border-width: 1px 0 0;
    margin: 1em 0;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0.8em 0;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0.6em 0;
    }
  }

  &--black {
    border-color: #3a3839;
  }
}

.default-sub-section-title {
  color: $default-text-color;
  align-self: flex-start;
  box-shadow: 0px 2px 0px $default-light-gray;
  padding: 0 10em 0.1em 0;
  margin: 0 0 1.2em;

  @media (max-width: $very-small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    margin: 0 0 0.8em;
  }

  @media (max-width: $small-width-breakpoint) {
    font-size: 0.9em;
  }

  @media (max-width: $very-small-width-breakpoint) {
    align-self: stretch;
    padding: 0 0.1em 0.1em;
    font-size: 0.85em;
  }

  &__text {
    text-transform: uppercase;
    font-weight: bolder;

    &:not(:first-child) {
      margin: 0 0 0 0.4em;
    }
  }
}
