@import '../color_variables.scss';
@import '../size_variables.scss';


.customer-support {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 6em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 5em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 4em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3.5em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__wrapper {
    width: 100%;
    max-width: 90em;
    flex: 1;
  }

  &__ticket-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    margin: 0 0 2em;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
        text-align: center;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.3em 0.2em;
      }
    }

    &__no-entry-message {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: center;
      font-size: 1.2em;
    }

    &__entry {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      font-family: 'Montserrat', sans-serif;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:first-child) {
        margin: 0.8em 0 0;
      }

      &__status {
        background: linear-gradient(338deg, #8f8f8f, #dddddd, #8f8f8f);
        color: $default-black-color;

        &,
        &--green,
        &--red {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          font-family: 'Iceland', cursive;
          font-size: 1.2em;
          border: 1px solid $default-black-color;
          border-bottom-width: 0;
          border-radius: 3px 3px 0 0;
          // margin: 0 0 0 0.4em;
          padding: 0.2em 0.5em;
          text-transform: uppercase;
          font-weight: bold;
          text-shadow: 1px 1px white;
          box-shadow: inset 0 -1px 2px #00000057;
          min-width: 13.4em;
          text-align: center;
        }

        &--green {
          background: linear-gradient(338deg, #8fbd5d, #c9eba4, #8fbd5d);
          color: #2c4412;
          border-color: #2c4412;
        }
        &--red {
          background: linear-gradient(338deg, #dd6e6a, #f7c3c1, #dd6e6a);
          color: #5e1f1d;
          border-color: #5e1f1d;
        }

        &__note-text {
          text-shadow: none;
          font-size: 0.85em;
        }
      }

      &__content-wrapper {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        border: 1px solid $default-black-color;
        border-radius: 0 3px 3px 3px;
        background-color: #f1f1f1;
        padding: 0.6em 0.4em 0.4em;
        box-shadow: 1px 1px 0px 0px $default-black-color;
      }

      &__header {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 0.4em;

        &__date {
          position: absolute;
          top: 0.2em;
          font-family: 'Iceland', cursive;
          align-self: flex-end;
          color: #626262;
          font-size: 0.9em;
          font-weight: bold;
          margin: 0 0 0.2em;

          &__label {
            text-transform: uppercase;
            color: #626262;
          }
        }

        &__title {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          font-size: 1.1em;
          width: 100%;
          font-weight: bold;

          &__text {
            padding: 0.2em 0.4em;
            background: white;
            border: 1px solid #a3a3a3;
            border-radius: 3px;
            width: 100%;
            color: $default-black-color;
          }

          &__label {
            font-family: 'Iceland', cursive;
            font-weight: bold;
            color: #626262;
            text-transform: uppercase;
            margin: 0 0.4em 0 0;
            font-size: 0.8em;
          }
        }
      }

      &__description {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 1.1em;
        width: 100%;

        &__text {
          padding: 0.2em 0.4em;
          background: white;
          border: 1px solid #a3a3a3;
          border-radius: 3px;
          width: 100%;
          font-size: 0.9em;
          min-height: 5em;
          color: $default-black-color;
        }

        &__label {
          font-family: 'Iceland', cursive;
          font-weight: bold;
          color: #626262;
          text-transform: uppercase;
          margin: 0 0 0;
          font-size: 0.8em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin: 0.3em 0 0;
      }

      &__action {
        font-size: 1.4em;
        border-radius: 3px;
        box-shadow: none;
        padding: 0.4em;
        flex: none;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.3em;
        }
      }
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 0;
    background: white;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: #a75050;
      padding: 0.5em 1em;
      line-height: 1.8em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #a75050;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__action-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 1.2em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0.7em 0;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 0.6em 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0.5em 0;
    }
  }

  &__action {
    font-size: 1.8em;
    border-radius: 3px;
    box-shadow: none;
    padding: 0.3em 0.6em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.7em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.4em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.2em;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 !important;
    width: 100%;
    height: 90%;
    margin: 0 0.5em;
    align-self: center;

    @media (min-width: 1500px) {
      max-width: 90em;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      align-self: stretch;

      &__close-button {
        border-radius: 0;
        border-top-right-radius: 7px;
        font-size: 2em;
        padding: 0.2em 0.5em;
        max-width: 2em;
        box-shadow: none;

        @media (max-width: $medium-less-width-breakpoint) {
          max-width: 1.6em;
        }
        @media (max-width: $small-width-breakpoint) {
          border-top-right-radius: 3px;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.7em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.5em;
        }
      }

      &__title {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: center;
        flex: 1;
        padding: 0.3em 0.3em 0.2em;
        min-width: 0;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }
        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.75em;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.6em;
        }
        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.45em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 1.25em;
        }


        &--label {
          color: #707070;
          margin: 0 0.3em 0 0;
          font-size: 0.8em;

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.65em;
            margin: 0;
          }
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        background: #ede4e4;
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #dbbbbb;
        border-radius: 0;
      }
    }

    &__loading-icon {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../images/preloader.gif), white;
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
    }
  }

  &__messages-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    background: #ede4e4;
    padding: 2.5em 0 1em;
  }

  &__message-wrapper {
    &,
    &--left,
    &--right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 3em;
      width: 100%;

      &:not(:first-child) {
        margin: 2em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          margin: 1.75em 0 0;
        }
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 1.5em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        padding: 0 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        padding: 0 0.6em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0 0.4em;
      }
    }

    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }

  &__message {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 65%;

    @media (max-width: $small-width-breakpoint) {
      max-width: 80%;
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-width: 85%;
    }
    @media (max-width: $very-small-width-breakpoint) {
      max-width: 87%;
    }
    @media (max-width: $tiny-width-breakpoint) {
      max-width: 90%;
    }

    &__arrow {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    &__balloon {
      position: relative;
      background: white;
      box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
      padding: 0.4em;
      border-radius: 4px;
    }

    &__sent-text {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7em;
      color: #a98282;
      transform: translateY(-100%);
      min-width: 8em;
      width: max-content;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.65em;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: justify;
      white-space: pre-wrap;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.94em;
      }
    }
  }

  &__message-wrapper--left &__message__arrow {
    justify-content: flex-end;
    order: -1;
  }
  &__message-wrapper--right &__message__arrow {
    justify-content: flex-start;
    order: 2;
  }

  &__message-wrapper--left &__message__balloon {
    border-top-left-radius: 0;
    padding-left: 0.5em;
  }
  &__message-wrapper--right &__message__balloon {
    background: #f7bfaa;
    border-top-right-radius: 0;
    padding-right: 0.5em;
  }

  &__message-wrapper--left &__message__sent-text {
    text-align: left;
    left: 0.5em;
  }
  &__message-wrapper--right &__message__sent-text {
    text-align: right;
    right: 0.5em;
  }

  &__send-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    background: #e9edef;
    padding: 0.8em 1.2em;
    border-radius: 0 0 7px 7px;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 0 0 3px 3px;
    }
    @media (max-width: $smaller-width-breakpoint) {
      padding: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 0.6em 0.4em;
    }
    // @media (max-width: $very-small-width-breakpoint) {
    //   flex-flow: column nowrap;
    //   align-items: stretch;
    //   justify-content: center;
    // }

    &__input {
      flex: 1;

      & textarea {
        font-size: 1em;
        border: none;
        border-radius: 9px;
        padding: 0.6em 0.8em;
        color: $default-black-color;

        @media (max-width: $smaller-width-breakpoint) {
          border-radius: 5px;
        }
        @media (max-width: $very-small-width-breakpoint) {
          padding: 0.5em 0.6em;
          border-radius: 4px;
        }
        @media (max-width: $tiny-width-breakpoint) {
          padding: 0.5em 0.4em;
        }
      }
    }

    &__controls {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.6em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.5em;
      }
    }

    &__button {
      width: 8em;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 7em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        width: 6.5em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        width: 6.3em;
      }

      &,
      &--send {
        border-radius: 3px;
        font-size: 2em;
        padding: 0.2em 0.5em;
        box-shadow: none;

        &:first-child {
          margin: 0 0 0 6px;

          @media (max-width: $tiny-width-breakpoint) {
            margin: 0 0 0 4px;
          }
        }
      }

      &--send {
        width: 4.5em;
      }
    }
  }
}
