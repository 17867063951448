@import '../color_variables.scss';
@import '../size_variables.scss';


.register-support-ticket {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 6em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 5em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 4em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3.5em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    max-width: 90em;
    flex: 1;
  }

  &__external-text-link {
    background: #a75050;
    border-radius: 3px;
    padding: 0.2em 0.5em;
    margin: 0 0.4em;
    text-transform: uppercase;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__unit-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.1em;
      border-radius: 2px;
      padding: 0.7em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 0;
    background: white;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: #a75050;
      padding: 0.5em 1em;
      line-height: 1.8em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #a75050;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }

    &__red-text {
      color: #a75050;
    }
  }

  &__unit-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__option-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    list-style: none;

    &__item {
      &:not(:last-child) {
        margin: 0 0 1em;

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0.6em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0.4em;
        }
      }

      &__button {
        width: 100%;
        padding: 0.4em 0.4em;
        border-radius: 3px;
        font-size: 3.1em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 2.8em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 2.4em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 1.9em;
        }
        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.6em;
          box-shadow: none;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.3em;
        }
        @media (max-width: $tiny-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.1em;
        }

        & > p {
          max-width: 15em;
        }
      }
    }
  }

  &__previous-selection {
    margin: 0 0 0.5em;
  }

  &__text-area-input {
    font-family: 'Montserrat', sans-serif;

    & textarea {
      border-radius: 3px;
      border-color: #878485 !important;

      &:focus {
        border-color: #3a3839 !important;
      }
    }
  }

  &__send-button {
    width: 100%;
    padding: 0.3em 0.4em;
    border-radius: 3px;
    font-size: 2.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 2.6em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 2.2em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1.4em;
      box-shadow: none;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.1em;
    }
    @media (max-width: $tiny-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1em;
    }

    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: stretch;
      margin: 1em 0 0;
    }
  }
}
