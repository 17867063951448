@import '../color_variables.scss';
@import '../size_variables.scss';

.accordion-section {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  &__header {
    &,
    &--visible {
      background: #3a3839;
      border: 1px solid #3a3839;
      color: white;
      cursor: pointer;
    }

    &,
    &--disabled {
      border-radius: 3px;

      &,
      &--visible {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        -webkit-tap-highlight-color:  transparent;
        font-size: 1em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
        }
        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.8em;
        }
        @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.75em;
        }
      }

      &--visible {
        border-radius: 3px 3px 0 0;
      }

      &__text {
        font-weight: lighter;
      }

      &__text-icon {
        font-size: 0.9em;
        margin: 0 0.5em 0 0;
      }
    }

    &--disabled {
      &,
      &--visible {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }
    }
  }

  &__content {
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.4em;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em;
    border: 1px solid #3a3839;
    border-top-width: 0;
    border-radius: 0 0 3px 3px;

    @media (max-width: $medium-less-width-breakpoint) {
      padding: 0.8em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      padding: 0.6em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 0.5em;
    }
  }
}
