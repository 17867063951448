@import '../color_variables.scss';
@import '../size_variables.scss';


.home-training {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }

    &__red-text {
      color: #a75050;
    }
  }

  &__training-button {
    // font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1.6em;
    padding: 0.4em;
    margin: 0.2em 0;
    border-radius: 3px;
    box-shadow: none;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.8em;
      border-radius: 2px;
      padding: 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__main-message {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;
    font-weight: bold;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__training-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 0.5em;
  }

  &__no-description-video-warning-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__no-description-video-warning {
    text-transform: uppercase;
    font-weight: 3em;
    font-weight: bold;
    background: $site-red-color;
    border-radius: 3px;
    padding: 0.5em 1em;;
    color: white;
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 1px 0 0;
    margin: 1em 0;
  }

  &__how-to-use-text,
  &__note-text {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
    color: $default-black-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      text-align: justify;
    }
  }

  &__note-text {
    margin: 1em 0 0;
    color: #1fa053;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  &__how-to-use-link {
    margin: 0 0.4em;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    color: $site-red-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__period-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 0.5em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      background: #ae7cdc;
      border: 1px solid #ae7cdc;
      color: white;
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        -webkit-tap-highlight-color:  transparent;

        &__text {
          font-weight: lighter;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &--disabled {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }

      &__disabled-text {
        text-transform: uppercase;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #ae7cdc;
      border-top-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        padding: 0.6em;
      }
    }
  }
}
