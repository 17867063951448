@import '../color_variables.scss';
@import '../size_variables.scss';

$scale-reference-1: #a50026;
$scale-reference-2: #d73027;
$scale-reference-3: #f46d43;
$scale-reference-4: #fdae61;
$scale-reference-5: #fee08b;
$scale-reference-6: #d9ef8b;
$scale-reference-7: #a6d96a;
$scale-reference-8: #66bd63;
$scale-reference-9: #1a9850;
$scale-reference-10: #006837;

$training-exercise-header-color: rgba(0, 0, 0, 0.38);
$training-content-background: #181e26;
$training-exercise-reps-background: #b7ae44;

@keyframes infinite-slide {
  from {
    transform: translateX(13.333%);
  }

  to {
    transform: translateX(-13.333%);
  }
}

.checkin {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;
  max-width: 100em;
  align-self: center;
  width: 100%;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }

    &__red-text {
      color: #a75050;
    }
  }

  &__service-button,
  &__unit-button,
  &__checkin-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.1em;
      border-radius: 2px;
      padding: 0.7em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }
  }

  &__service-button,
  &__unit-button {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__checkin-button {
    margin: 0;
    width: 8em;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1em;
    }
  }

  &__main-message {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;
    font-weight: bold;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 2px 0 0;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__service-selector,
  &__unit-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__date {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 0.5em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      background: #5ea9bf;
      border: 1px solid #5ea9bf;
      color: white;
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        -webkit-tap-highlight-color:  transparent;

        &__text {
          font-weight: lighter;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &--disabled {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }

      &__disabled-text {
        text-transform: uppercase;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #5ea9bf;
      border-top-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        padding: 0.6em;
      }
    }

    &__view-training-button {
      font-size: 1.8em;
      padding: 0.3em 0;
      border-radius: 4px;
      margin: 0 0 0.5em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        box-shadow: none;
        border-radius: 2px;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.4em;
      }
    }
  }

  &__time {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 0.9em;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    &__text {
      // font-family: 'Orbitron', sans-serif;
      font-family: 'Orbitron', monospace;
      color: $default-black-color;
      font-size: 1.7em;
      font-weight: bold;
      width: 3.6em;

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.5em;
      }
    }

    &__status-text {
      background: #7ebf5e;

      &,
      &--alert,
      &--limited,
      &--disabled {
        font-family: 'Montserrat', sans-serif;
        margin: 0 0 0 2em;
        padding: 0.5em 0.8em;
        color: white;
        border-radius: 4px;
        text-transform: uppercase;

        @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
          border-radius: 2px;
          margin: 0 0 0 1em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          margin: 0 0 0 0.8em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &--alert {
        background: #ff8124;
      }

      &--limited {
        background: #b7bf45;
      }

      &--disabled {
        background: #afafaf;
        color: #4a4a4a;
      }
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &__title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: $default-black-color;
        font-size: 2em;
        text-transform: uppercase;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }
  }

  &__scale {
    font-family: 'Montserrat', sans-serif;
    margin: 0.6em 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.6em;
      color: $default-black-color;
      margin: 0 0 0.5em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.75em;
      }
    }

    &__highlighted-title {
      font-weight: bold;
      margin: 0 0 0 0.4em;
      flex: 0 1;
      white-space: nowrap;
    }

    &__ranges {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      overflow: auto;

      @media (max-width: $very-small-width-breakpoint) {
        max-width: 400px;
      }

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__range {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: stretch;
      list-style: none;
      flex: 1 1;

      &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          font-size: 3em;
          padding: 0.2em 0;

          @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
            font-size: 2.7em;
          }

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            font-size: 2.5em;
          }

          @media (max-width: $small-width-breakpoint) {
            font-size: 2em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
            font-size: 1.8em;
          }
        }
      }

      &__image,
      &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          border: 0 solid white;
          border-right-width: 1px;
          border-left-width: 1px;
          background: $default-black-color;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            border-right-width: 0;
          }
        }

        &--1 {
          color: $scale-reference-1;
        }
        &--2 {
          color: $scale-reference-2;
        }
        &--3 {
          color: $scale-reference-3;
        }
        &--4 {
          color: $scale-reference-4;
        }
        &--5 {
          color: $scale-reference-5;
        }
        &--6 {
          color: $scale-reference-6;
        }
        &--7 {
          color: $scale-reference-7;
        }
        &--8 {
          color: $scale-reference-8;
        }
        &--9 {
          color: $scale-reference-9;
        }
        &--10 {
          color: $scale-reference-10;
        }
      }

      &:first-of-type &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0.1em 0 0 0;
          border-left-width: 0;
        }
      }
      &:last-of-type &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0.1em 0 0;
          border-right-width: 0;
        }
      }

      &:first-of-type &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0 0 0.1em;
          border-left-width: 0;
        }
      }
      &:last-of-type &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0 0.1em 0;
          border-right-width: 0;
        }
      }

      &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          text-transform: uppercase;
          font-size: 1.1em;
          font-weight: bold;
          padding: 0.5em 0;
          min-width: 9em;

          @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
            font-size: 1em;
          }

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            font-size: 0.9em;
          }

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.8em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
            font-size: 0.8em;
          }
        }

        &__text {
          font-size: 1em;
        }
      }

      &__options {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        flex: 1;
      }

      &__option {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          padding: 0.6em 0.2em;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: none;
          user-select: none;
          font-size: 1em;
          text-decoration: none;
          min-width: 35px;
          // min-height: 35px;
          min-width: 2.7em;
          font-weight: bold;
          border: 1px solid $default-black-color;
          background: $default-black-color;
          flex: 1 1;

          @media (max-width: $medium-less-width-breakpoint) {
            border-right-width: 0;
          }

          &:disabled {
            cursor: default;
            color: white;
          }
        }

        &--1 {
          color: $scale-reference-1;
          border-color: $scale-reference-1;

          &:disabled {
            background: $scale-reference-1;
          }
        }
        &--2 {
          color: $scale-reference-2;
          border-color: $scale-reference-2;

          &:disabled {
            background: $scale-reference-2;
          }
        }
        &--3 {
          color: $scale-reference-3;
          border-color: $scale-reference-3;

          &:disabled {
            background: $scale-reference-3;
          }
        }
        &--4 {
          color: $scale-reference-4;
          border-color: $scale-reference-4;

          &:disabled {
            background: $scale-reference-4;
          }
        }
        &--5 {
          color: $scale-reference-5;
          border-color: $scale-reference-5;

          &:disabled {
            background: $scale-reference-5;
          }
        }
        &--6 {
          color: $scale-reference-6;
          border-color: $scale-reference-6;

          &:disabled {
            background: $scale-reference-6;
          }
        }
        &--7 {
          color: $scale-reference-7;
          border-color: $scale-reference-7;

          &:disabled {
            background: $scale-reference-7;
          }
        }
        &--8 {
          color: $scale-reference-8;
          border-color: $scale-reference-8;

          &:disabled {
            background: $scale-reference-8;
          }
        }
        &--9 {
          color: $scale-reference-9;
          border-color: $scale-reference-9;

          &:disabled {
            background: $scale-reference-9;
          }
        }
        &--10 {
          color: $scale-reference-10;
          border-color: $scale-reference-10;

          &:disabled {
            background: $scale-reference-10;
          }
        }
      }

      &__option-text,
      &__option-number {
        &,
        &--flex {
          font-family: 'Montserrat', sans-serif;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          text-shadow: 0 0 2px $default-black-color;
        }

        &--flex {
          flex: 1;
        }
      }

      &__option-number {
        &,
        &--flex {
          font-size: 1.5em;

          @media (max-width: $very-small-width-breakpoint) {
            font-size: 1em;
          }
        }
      }

      &__option-text {
        &,
        &--flex {
          @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
            font-size: 0.75em;
          }

          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 0.6em;
          }

          @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
            display: none;
          }
        }
      }
    }
  }

  &__training {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: stretch;
    background: linear-gradient(150deg, #cacaca, #797979);
    padding: 4px;
    border-radius: 4px;

    @media (max-width: $small-width-breakpoint) {
      padding: 2px;
    }

    &:not(:last-child) {
      margin: 0 0 1em;
    }

    &__header,
    &__content-container {
      background: linear-gradient(to right, lighten($training-content-background, 10%), $training-content-background);
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;

      &__date {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.94em;
        }
      }

      &__separator {
        display: block;
        width: 2.5em;
        background: linear-gradient(136deg, #bdbdbd, #bababa 55%, transparent 56%);
      }

      &__name,
      &__not-today {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 1em;
        color: white;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0 0.4em 0 0.5em;
        }
      }

      &__name {
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.1em;
        }
      }

      &__main-name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: $site-red-color;
        font-weight: bold;
        margin: 0 0.5em 0;
        font-size: 1.2em;
      }

      &__not-today {
        background: linear-gradient(150deg, #8a8a8a, #7b7b7b);
        text-transform: uppercase;
        color: #dcdcdc;
        font-weight: bold;
        text-shadow: 1px 1px 1px #383838;

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.85em;
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: stretch;
      padding: 0.5em;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 0.8em;
    }

    &__note {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;
      text-align: justify;
      padding: 0;
      background: white;
      border-radius: 3px;
      box-shadow: 2px 2px 4px 0px #6b6b6b;
      margin: 0.5em 0 1.2em;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.92em;
        border-radius: 2px;
      }
    }

    &__note-icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.5em 0.65em;
      font-size: 1.5em;
      background: $site-red-color;
      border: 1px solid $site-red-color;
      margin: 0 0.5em 0 0;
      border-radius: 3px 0 0 3px;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 2px 0 0 2px;
      }
    }

    &__note-text {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $site-background-color;
      font-weight: bold;
      text-align: justify;
      padding: 1em 1em 1em 0;
      flex: 1;
      white-space: pre-wrap;

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.6em 0.8em 0.6em 0;
      }
    }

    &__exercise-group {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 2px solid white;
      border-radius: 4px;
      margin: 0 0 1em;
      overflow: auto;
      overflow-y: hidden;
      flex-shrink: 0;
      color: white;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 0 0 0.7em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.96em;
        border-radius: 2px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 0 0 0.4em;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $very-small-width-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 0.4em;
      }

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.3em 0.8em 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      &__exercises-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__note-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0 0 0 0.8em;
        margin: 0.5em 0 0.2em;
      }

      &__note-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        margin: 0 0.4em 0 0;
      }

      &__note-text {
        background: white;
        padding: 0.5em;
        color: black;
        border-radius: 3px;
        flex: 1;
        white-space: pre-wrap;
      }
    }

    &__exercise {
      &__header {
        &,
        &--centered {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          justify-content: flex-start;
          height: 1.6em;
          min-height: 1.6em;
          font-weight: bold;
          color: $training-exercise-header-color;
          text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.16);
          padding: 0 1em;

          @media (max-width: $smaller-width-breakpoint) {
            padding: 0 0.7em;
          }
        }

        &--centered {
          justify-content: center;
          text-align: center;
        }
      }

      &__reps,
      &__intensities,
      &__intensities--collapsed,
      &__names,
      &__diffculties,
      &__easier-options,
      &__references {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        text-align: left;
      }

      &__intensities-wrapper {
        &--vertical,
        &--horizontal {
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }

        &--vertical {
          flex-flow: column nowrap;
        }

        &--horizontal {
          flex-flow: row nowrap;
        }
      }

      &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__header {
        border-right: 1px solid $training-exercise-header-color;
      }

      &__intensities-wrapper--horizontal &__intensities:not(:first-child) &__header {
        border-left: 1px solid rgba(255, 255, 255, 0.16);
      }

      &__intensities,
      &__names,
      &__diffculties,
      &__easier-options {
        flex: 1;
      }

      &__reps-text {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to bottom, lighten($training-exercise-reps-background, 30%), $training-exercise-reps-background 30%, $training-exercise-reps-background 70%, darken($training-exercise-reps-background, 25%));
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 3px black;
        flex: 1;
        padding: 0 0.5em;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0 0.2em;
        }
      }

      &__reps-text-span {

      }

      &__reps-text,
      &__intensity-text,
      &__intensity-cycle-text,
      &__difficult-text,
      &__name-text,
      &__easier-option-text,
      &__reference-link {
        border: 0 solid white;
        white-space: nowrap;

        &:not(:last-child) {
          border-bottom-width: 1px;
        }
      }

      &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__intensity-cycle-text {
        border-right: 1px solid rgba(255, 255, 255, 0.32);
      }

      &__intensity-text,
      &__intensity-cycle-text,
      &__difficult-text,
      &__name-text,
      &__easier-option-text,
      &__reference-link {
        padding: 0.2em 1em;
        background: rgba(0, 0, 0, 0.25);

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.2em 0.7em;
        }
      }

      &__reference-link {
        text-align: center;
        color: white;
      }

      &__first-column {
        width: 0.4em;
        min-width: 0.4em;
      }

      &__last-column {
        flex: 1;
        min-width: 0.4em;
      }
    }
  }

  &__note {
    margin: 0 0 0.5em;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: center;

      @media (max-width: $medium-less-width-breakpoint) {
        text-align: justify;
      }

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__overlay-note {
    margin: 0.3em 0 0.5em ;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__parsed-text {
    white-space: pre;
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 0;
    background: white;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    // @media (max-width: $extra-high-width-breakpoint) {
    //   font-size: 0.9em;
    //   margin: 2.5em 0 0;
    // }
    // @media (max-width: $medium-plus-width-breakpoint) {
    //   margin: 2em 0 0;
    // }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
      // margin: 1.5em 0 0;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      // margin: 1em 0 0;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
      // margin: 0.5em 0 0;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      // color: $site-red-color;
      color: #a75050;
      padding: 0.5em 1em;
      line-height: 1.8em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      // background: $site-red-color;
      background: #a75050;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__external-text-link {
    background: #a75050;
    border-radius: 3px;
    padding: 0.2em 0.5em;
    margin: 0 0.4em;
    text-transform: uppercase;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__promotion-banner {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    font-family: 'Montserrat', sans-serif;
    margin: 0 0 1em;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 540px) {
      margin: 0 0 0.5em;
    }

    &__image-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;
    }

    &__image {
      max-width: 100%;
    }

    &__message {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      background: #479849;
      padding: 0 1em;
      font-weight: 600;
      flex-shrink: 0;
      font-size: 1.25em;
      color: white;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.1em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.95em;
      }
      @media (max-width: 980px) {
        font-size: 0.85em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.8em;
        padding: 0 0.8em;
      }
      @media (max-width: 800px) {
        font-size: 0.75em;
        padding: 0 0.8em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 0.6em .8em;
        font-size: 1em;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 540px) {
        padding: 2vw 2.5vw;
        font-size: 3vw;
        align-items: flex-start;
      }

      &__line {
        &,
        &--centered,
        &--emphasis {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;

          &:not(:last-child) {
            margin: 0 0 1em;

            @media (max-width: $large-width-breakpoint) {
              margin: 0 0 0.5em;
            }
            @media (max-width: $small-width-breakpoint) {
              margin: 0 0 0.2em;
            }
          }
        }

        &--emphasis {
          border: 1px solid white;
          border-radius: 5px;
          padding: 0.1em 0.2em;
          text-transform: uppercase;
          font-size: 1.223em;
        }

        &--centered {
          align-self: center;
        }

        &__icon {
          margin: 0 0.3em 0 0;
        }
      }

      &__phone {
        font-size: 0.85em;
      }
    }

    &__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;
      text-decoration: none;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      font-size: 1em;
      outline: none;
      user-select: none;
      max-width: 100%;

      @media (max-width: $small-width-breakpoint) {
        flex-flow: column nowrap;
      }
    }
  }

  &__banner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 5em;
    overflow: hidden;
    position: relative;
    background: linear-gradient(to right, #2f2f2f, #565656, #2f2f2f);
    margin: 0 0 0.8em;
    border-radius: 5px;

    &::before,
    &::after {
      box-shadow: 0 0 10px 2px white;
    }

    &::before {
      display: block;
      position: absolute;
      content: '';
      box-shadow: 0 0 10px 2px black;
      width: 100%;
      height: 0;
      top: 0;
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      box-shadow: 0 0 10px 2px black;
      width: 100%;
      height: 0;
      bottom: 0;
    }

    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 1em 0.8em;
    }

    &__title {
      font-weight: bolder;
      text-shadow: 3px 1px 0px #000000a6, 0px 0px 5px #ffffff52, 0px 0px 5px #ffffffa3;
      color: #ffffff;
      font-size: 1.8em;
      text-transform: uppercase;
      flex: 1;

      @media (max-width: 910px) {
        font-size: 1.7em;
      }

      @media (max-width: 750px) {
        font-size: 1.5em;
        text-align: center;
      }

      @media (max-width: 490px) {
        font-size: 1em;
      }

      &--emphasis {
        font-size: 1.5em;
        color: $site-red-color;
        text-shadow: 3px 1px 0px #0000006e, 0px 0px 5px #ff737952, 0px 0px 5px #fb71756b;
      }

      &--yellow {
        color: #f6ff68;
        text-shadow: 3px 1px 0px #0000006e, 0px 0px 5px #f6ff687a, 0px 0px 5px #f6ff687d;
      }
      &--green {
        font-size: 1.5em;
        color: #51d751;
        text-shadow: 3px 1px 0px #00000096, 0px 0px 5px #82ff827d, 0px 0px 5px #82ff827d;
      }
    }

    &__link-button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      text-decoration: none;
      padding: 0.3em 0.6em;
      // background: transparent;
      border: 2px dashed white;
      border-radius: 3px;
      // color: white;
      // font-weight: 500;
      font-size: 1.2em;
      text-transform: uppercase;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      text-align: center;
      background: white;
      color: #00a400;
      box-shadow: 0 0 3px white;
      font-weight: bolder;

      @media (max-width: 750px) {
        font-size: 1.1em;
      }

      @media (max-width: 490px) {
        font-size: 1em;
      }
    }

    & &__link-button {
      background: transparent;
      color: white;
      font-weight: 500;
      box-shadow: none;

      &:hover {
        background: white;
        // color: $site-red-color;
        color: #00a400;
        box-shadow: 0 0 3px white;
        font-weight: bolder;
      }
    }

    &__background {
      position: absolute;
      background: linear-gradient(169deg, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d, #bbb4d3, #bee6f9, #a6e98d);
      height: 100%;
      width: 400%;
      will-change: transform;
      transform-origin: center center;
      transform: translateX(13.333%);
      animation: infinite-slide 9s linear 0.9s infinite backwards;
    }
  }

  &__notification-header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    margin: 0 0 1em;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }

    &__title {
      color: white;
      text-shadow: 2px 2px 1px #9b1212;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2em;

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.2em;
        background: #ff9595;
        border: 1px solid #9b1212;
        border-bottom-width: 0;
        border-radius: 5px 5px 0 0;
        width: 100%;

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          border-radius: 3px 3px 0 0;
        }
      }
    }

    &__text {
      color: $default-black-color;
      text-transform: uppercase;
      font-size: 1em;

      &--highlight {
        font-weight: bold;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        text-align: justify;
        padding: 0.3em;
        background: white;
        border: 1px solid #9b1212;
        border-radius: 0 0 5px 5px;
        width: 100%;

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
}
