@import '../../color_variables.scss';
@import '../../size_variables.scss';
@import '../../constants.scss';


$default-red-color: #ff7878;

$scale-reference-1: #006837;
$scale-reference-2: #1a9850;
$scale-reference-3: #66bd63;
$scale-reference-4: #a6d96a;
$scale-reference-5: #d9ef8b;
$scale-reference-6: #fee08b;
$scale-reference-7: #fdae61;
$scale-reference-8: #f46d43;
$scale-reference-9: #d73027;
$scale-reference-10: #a50026;


.custom-training-data{
  &__warning-container {
    margin: 0 0 0.6em;

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 0.4em;
    }
  }

  &__input-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.1em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.1em;
    }

    &--horizontal {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__input-wrapper {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &,
    &--vertical {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      align-self: stretch;

      @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.85em;
      }

      &:not(:last-of-type) {
        margin: 0 0 0.6em;
      }
    }

    &--vertical {
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__input-tag-label,
  &__input-label {
    &,
    &--force-width {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.2em;
      font-weight: bold;
      color: $default-black-color;
      min-width: 7em;

      @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }
    }

    &--force-width {
      max-width: 7em;
    }
  }

  &__input-tag {
    font-weight: bold;
    text-align: justify;
    background: #e8e8e8;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: 0.5em;
    font-size: 1em;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 3px;
    }
  }

  &__input {
    &,
    &--rounded,
    &--extended,
    &--normal {
      margin-top: 0 !important;

      &:not(:last-of-type) {
        margin-right: 1em;
      }

      & input,
      & select {
        font-weight: bold;
        font-size: 1.2em;
        padding: 0.3em 0.5em;
      }
    }

    &--extended {
      flex: 1;
    }

    &--normal {
      flex-shrink: 0;
    }

    &,
    &--rounded {
      & input {
        max-width: 6em;

        @media (max-width: $small-width-breakpoint) {
          max-width: 5em;
        }
      }
    }

    &--normal input {
      width: 11em;
    }

    & input {
      border-radius: 5px 0 0 5px;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 3px 0 0 3px;
      }
    }

    & .default-input__input-suffix {
      border-radius: 0 5px 5px 0;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 0 3px 3px 0;
      }
    }

    &--rounded input,
    &--extended input,
    &--normal input,
    & select {
      border-radius: 5px;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 3px;
      }
    }
  }

  &__buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__save-button {
    margin: 0 0.5em 0 0;
  }

  &__save-button,
  &__cancel-button {
    flex: none;
    box-shadow: none;
    border-radius: 3px;
    font-size: 1.8em;
    padding: 0.2em 0.4em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.7em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      font-size: 1.1em;
    }
  }

  &__scale {
    &,
    &--highlight {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: stretch;
      transition: $default-transition-duration;
    }

    &--highlight {
      box-shadow: 0 0 0px 2px #ff7878;
      border-radius: 0.4em 0.4em 0 0;
      border: 2px solid $default-red-color;
      outline-offset: 0;
    }

    &__group {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: stretch;
      list-style: none;
      flex: 1 1;

      &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          font-size: 3em;
          padding: 0.2em 0;

          @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
            font-size: 2.7em;
          }

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            font-size: 2.5em;
          }

          @media (max-width: $small-width-breakpoint) {
            font-size: 2em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
            font-size: 1.8em;
          }
        }
      }

      &__image,
      &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          border: 0 solid white;
          border-right-width: 1px;
          border-left-width: 1px;
          background: $default-black-color;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            border-right-width: 0;
          }
        }

        &--1 {
          color: $scale-reference-1;
        }
        &--2 {
          color: $scale-reference-2;
        }
        &--3 {
          color: $scale-reference-3;
        }
        &--4 {
          color: $scale-reference-4;
        }
        &--5 {
          color: $scale-reference-5;
        }
        &--6 {
          color: $scale-reference-6;
        }
        &--7 {
          color: $scale-reference-7;
        }
        &--8 {
          color: $scale-reference-8;
        }
        &--9 {
          color: $scale-reference-9;
        }
        &--10 {
          color: $scale-reference-10;
        }
      }

      &:first-of-type &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0.1em 0 0 0;
          border-left-width: 0;
        }
      }
      &:last-of-type &__image {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0.1em 0 0;
          border-right-width: 0;
        }
      }

      &:first-of-type &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0 0 0.1em;
          border-left-width: 0;
        }
      }
      &:last-of-type &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          border-radius: 0 0 0.1em 0;
          border-right-width: 0;
        }
      }

      &__description {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          text-transform: uppercase;
          font-size: 1.1em;
          font-weight: bold;
          padding: 0.5em 0;
          min-width: 9em;

          @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
            font-size: 1em;
          }

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            font-size: 0.9em;
          }

          @media (max-width: $small-width-breakpoint) {
            font-size: 0.8em;
          }

          @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
            font-size: 0.8em;
          }
        }

        &__text {
          font-size: 1em;
        }
      }

      &__options {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        flex: 1;
      }

      &__option {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8,
        &--9,
        &--10 {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          padding: 0.4em 0.6em;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: none;
          user-select: none;
          font-size: 1em;
          text-decoration: none;
          min-width: 35px;
          min-height: 35px;
          font-weight: bold;
          border: 1px solid $default-black-color;
          background: $default-black-color;
          flex: 1 1;

          @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
            border-right-width: 0;
          }

          &:disabled {
            cursor: default;
            color: white;
          }
        }

        &--1 {
          color: $scale-reference-1;
          border-color: $scale-reference-1;

          &:disabled {
            background: $scale-reference-1;
          }
        }
        &--2 {
          color: $scale-reference-2;
          border-color: $scale-reference-2;

          &:disabled {
            background: $scale-reference-2;
          }
        }
        &--3 {
          color: $scale-reference-3;
          border-color: $scale-reference-3;

          &:disabled {
            background: $scale-reference-3;
          }
        }
        &--4 {
          color: $scale-reference-4;
          border-color: $scale-reference-4;

          &:disabled {
            background: $scale-reference-4;
          }
        }
        &--5 {
          color: $scale-reference-5;
          border-color: $scale-reference-5;

          &:disabled {
            background: $scale-reference-5;
          }
        }
        &--6 {
          color: $scale-reference-6;
          border-color: $scale-reference-6;

          &:disabled {
            background: $scale-reference-6;
          }
        }
        &--7 {
          color: $scale-reference-7;
          border-color: $scale-reference-7;

          &:disabled {
            background: $scale-reference-7;
          }
        }
        &--8 {
          color: $scale-reference-8;
          border-color: $scale-reference-8;

          &:disabled {
            background: $scale-reference-8;
          }
        }
        &--9 {
          color: $scale-reference-9;
          border-color: $scale-reference-9;

          &:disabled {
            background: $scale-reference-9;
          }
        }
        &--10 {
          color: $scale-reference-10;
          border-color: $scale-reference-10;

          &:disabled {
            background: $scale-reference-10;
          }
        }
      }

      &__option-number,
      &__option-text {
        &,
        &--flex {
          font-family: 'Montserrat', sans-serif;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          text-shadow: 0 0 2px $default-black-color;
        }

        &--flex {
          flex: 1;
        }
      }

      &__option-number {
        &,
        &--flex {
          font-size: 1.5em;

          @media (max-width: $small-width-breakpoint) {
            font-size: 1.2em;
          }

          @media (max-width: $very-small-width-breakpoint) {
            font-size: 1em;
          }
        }
      }

      &__option-text {
        &,
        &--flex {
          font-size: 0.9em;

          @media (max-width: $large-width-breakpoint) {
            font-size: 0.6em;
          }

          @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
            display: none;
          }
        }
      }
    }
  }

  &__notification-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    text-align: justify;
    padding: 0;
    background: white;
    border-radius: 3px;
    margin: 0.3em 0;
    border: 1px solid #e89d3f;
    // min-width: 32em;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0.5em 0 0.6em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.5em 0.65em;
      font-size: 1.5em;
      background: #e89d3f;
      margin: 0 0.5em 0 0;
      border-radius: 3px 0 0 3px;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 1.1em;
        padding: 0.5em 0.45em;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $default-black-color;
      font-weight: bold;
      text-align: justify;
      padding: 1em 1em 1em 0;
      flex: 1;
      color: #e6942e;

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        padding: 0.6em 0.6em 0.6em 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__small-note {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: justify;
    margin: 0.2em 0 0;
    font-size: 0.8em;
    font-weight: bold;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }
  }
}
