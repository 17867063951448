@import '../color_variables.scss';
@import '../size_variables.scss';

.default-header {
  background: $default-black-color;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 1px 4px 0 lighten($default-black-color, 10%);
  z-index: 1;

  &__logo-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    max-width: 9em;
    border: 4px solid $background-color;
    border-radius: 50%;
    padding: 0.3em;
    background: $default-black-color;
    margin: 0.3em 0 0;
    box-shadow: 0 1px 6px 1px lighten($default-black-color, 10%);

    @media (max-width: $medium-width-breakpoint) {
      max-width: 8em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      max-width: 7em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      max-width: 6em;
    }

    @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
      max-width: 4.2em;
    }
  }

  &__logo {
    width: 100%;
  }

  &__back-button,
  &__exit-button {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: white;
    background: none;
    align-self: center;
    text-transform: uppercase;
    padding: 0.3em 0.5em;
  }

  &__username {
    font-size: 1.4em;
    position: absolute;
    right: 3.6em;
    font-family: 'Montserrat', sans-serif;
    color: white;
    align-self: center;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.2em;
      right: 3.4em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $small-width-breakpoint) {
      right: 3.2em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
      right: 3.3em;
    }
  }

  &__back-button {
    font-size: 2em;
    left: 1em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.8em;
      left: 0.9em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.7em;
      left: 0.8em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      font-size: 1.5em;
      left: 0.5em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1.2em;
      left: 0.3em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1em;
    }

    &__icon:not(:last-child) {
      margin: 0 0.3em 0 0;
    }
  }

  &__exit-button {
    font-size: 1.5em;
    right: 1em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.4em;
      right: 0.8em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $small-width-breakpoint) {
      right: 0.5em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1.1em;
      right: 0.3em;
    }
  }
}
