@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';

.prompt {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: 0.5em;
  right: 0;
  background: $default-black-color;
  padding: 0 0.4em;

  @media (max-width: $small-width-breakpoint) {
    font-size: 0.8em;
  }

  @media (max-width: $smaller-width-breakpoint) {
    padding: 0;
  }

  &__logo {
    max-width: 4em;
    margin: 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 0.4em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      display: none;
    }
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    color: white;
    max-width: 20em;
    text-align: justify;
    margin: 1em 1em 1em 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      margin: 1em 0.8em 1em 0.4em;
    }
  }

  &__action-button,
  &__cancel-button {
    font-size: 1.5em;
    padding: 0.3em 0.5em;
    border-radius: 2px;
    flex: none;
  }

  &__cancel-button {
    margin: 0 0.5em;

    @media (max-width: $smaller-width-breakpoint) {
      margin-right: 0.2em;
    }
  }
}
