@import '../color_variables.scss';
@import '../size_variables.scss';


$indicator-background-color: #e6e6e6;

$default-transition: all 0.3s cubic-bezier(0.22, 0.97, 0.58, 1);


.personal-training {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }

    &__red-text {
      color: #a75050;
    }
  }

  &__training-button {
    // font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1.6em;
    padding: 0.4em;
    margin: 0.2em 0;
    border-radius: 3px;
    box-shadow: none;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.8em;
      border-radius: 2px;
      padding: 0.6em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__main-message {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;
    font-weight: bold;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__training-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 0.5em;
  }

  &__no-description-video-warning-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__no-description-video-warning {
    text-transform: uppercase;
    font-weight: 3em;
    font-weight: bold;
    background: $site-red-color;
    border-radius: 3px;
    padding: 0.5em 1em;;
    color: white;
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 1px 0 0;
    margin: 1em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 0.5em 0;
    }
  }

  &__period-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 0.5em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    &__header {
      background: #ae7cdc;
      border: 1px solid #ae7cdc;
      color: white;
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        -webkit-tap-highlight-color:  transparent;

        &__text {
          font-weight: lighter;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &--disabled {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }

      &__disabled-text {
        text-transform: uppercase;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 1em;
      border: 1px solid #ae7cdc;
      border-top-width: 0;

      @media (max-width: $smaller-width-breakpoint) {
        padding: 0.6em;
      }
    }
  }

  &__unit-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.1em;
      border-radius: 2px;
      padding: 0.7em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__default-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    margin: 0 0 1.5em;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.1em;
      }

      &__end-date {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;

        &__label {
          color: white;
          font-size: 0.6em;
          text-transform: uppercase;
        }

        &__text {
          font-size: 0.8em;
        }
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.3em 0.2em;
      }
    }

    &__no-entry-message {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: center;
      font-size: 1.2em;
    }
  }

  &__training-option {
    &,
    &--highlighted {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      font-family: 'Montserrat', sans-serif;
      border: 1px solid $default-black-color;
      border-radius: 3px 3px 0 0;
      // min-width: 35em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:first-child) {
        margin: 0.5em 0 0;
      }
    }

    &--highlighted {
      border-color: $site-red-color;
    }

    &__label {
      cursor: pointer;

      &,
      &--unclickable {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 1.3em;
        background: $default-black-color;
        color: white;
        padding: 0.3em;
        margin: 0 0.4em 0 0;
        flex-shrink: 0;
        text-align: center;
        width: 15em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          padding: 0;
          font-size: 1.15em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__date {
        font-size: 0.7em;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 0.5em;
      }
    }

    &--highlighted &__label {
      background: $site-red-color;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
      margin: 0.5em 0 0.2em;
      padding: 0 0 0.3em;
      text-align: justify;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $site-red-color;
        border-radius: 0.4em;
      }
    }

    &__note-label {
      color: $site-red-color;
      font-weight: bold;
      margin: 0 0.4em 0 0;

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 0 0 0.2em;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.9em;
      white-space: pre-wrap;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.75em;
      }
    }

    &__notification {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.8em;
      color: $site-red-color;
      text-transform: uppercase;
      font-weight: bold;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.7em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      flex: 1;
    }

    &__action {
      font-size: 1.4em;
      border-radius: 3px;
      box-shadow: none;
      padding: 0.4em;
      flex: none;
      margin: 0.2em 0.2em 0.2em 0;

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.3em;
      }

      // &:not(:first-child) {
      //   margin-left: 0.5em;
      //
      //   @media (max-width: $smaller-width-breakpoint) {
      //     margin-left: 0.4em;
      //   }
      // }
      //
      // &:last-child {
      //   margin-right: 0.2em;
      // }
    }
  }

  &__default-icon {
    font-size: 0.9em;
  }

  &__indicators-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__indicators-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $smaller-width-breakpoint) {
      flex-flow: column nowrap;
      order: 2;
    }
  }

  &__progress-indicator {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 8.5em;
    height: 8.5em;
    background: center / contain no-repeat url(../images/progress_background.svg);
    flex-shrink: 0;

    @media (max-width: $medium-width-breakpoint) {
      width: 8em;
      height: 8em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      width: 7em;
      height: 7em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      order: 1;
      align-self: center;
      margin: 0 0 0.5em;
    }

    &__text {
      font-family: 'Orbitron', sans-serif;
      font-weight: bold;
      font-size: 1.5em;
      color: $default-black-color;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.1em;
      }
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2em 0.6em;
    border-radius: 3px;
    color: $default-black-color;
    background: $indicator-background-color;
    margin: 0 0.5em 0 0;

    @media (max-width: $smaller-width-breakpoint) {
      flex: 1;
      justify-content: space-between;
      margin: 0;
    }

    &__label {
      font-size: 1.2em;
      margin: 0 0.5em 0 0;
    }

    &__value {
      padding: 0.4em 0.6em;
      border-radius: 3px;
      background: $default-black-color;
      color: white;
      white-space: nowrap;
    }

    & + & {
      @media (max-width: $smaller-width-breakpoint) {
        margin: 0.5em 0 0;
      }
    }
  }

  &__graphs-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.4em;
    }
  }

  &__graphs-wrapper {
    min-width: 30em;
  }

  &__graph {
    &:not(:last-of-type) {
      margin: 1em 0 0;
    }
  }

  &__training-option-section,
  &__student-note {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 0.5em;
    }

    &__header {
      background: #a2a2a2;
      border: 1px solid #a2a2a2;
      color: #5d5d5d;
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        -webkit-tap-highlight-color:  transparent;

        &__text {
          font-weight: lighter;
        }

        &__text-icon {
          font-size: 0.9em;
          margin: 0 0.5em 0 0;
        }
      }

      &--disabled {
        background: #b5b5b5;
        color: #5a5a5a;
        cursor: default;
      }

      &__disabled-text {
        text-transform: uppercase;
      }
    }

    &__content {
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__content-wrapper {
      &,
      &--highlighted {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 1em;
        border: 1px solid #a2a2a2;
        border-top-width: 0;
        background: #f3f3f3;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.6em;
        }
      }
    }

    &__text {
      text-align: justify;
      white-space: pre-wrap;
      color: $default-black-color;
      padding: 0.2em 0 0.4em;
      overflow: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0.6em;
        height: 0.6em;

        @media (max-width: $medium-less-width-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #5d5d5d;
        border-radius: 0.4em;
      }
    }
  }

  &__student-note {
    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }
  }

  &__training-option-section {
    margin: 0.5em 0 0;

    &__header {
      &__visible-icon {
        font-size: 0.7em;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.8em;
          margin: 0 0 0 0.5em;
        }
      }
    }

    &__content-wrapper {
      border-width: 0 1px 1px;

      & {
        border-color: $default-black-color;
      }
      &--highlighted {
        border-color: $site-red-color;
      }
    }
  }

  &__how-to-use-text,
  &__note-text {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: bold;
    color: $default-black-color;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      text-align: justify;
    }
  }

  &__note-text {
    margin: 1em 0;
    color: #cc5454;
    font-size: 0.9em;
    text-transform: uppercase;
  }

  &__how-to-use-link {
    margin: 0 0 0 0.4em;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    color: $site-red-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__schedule-configuration {
    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      overflow-y: hidden;
      padding: 0;
      max-width: 100%;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2c3e50;
        border-radius: 0.4em;
      }
    }

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 0.5em;
    border-radius: 0;
    white-space: nowrap;
    margin: 0;
    font-family: 'Montserrat', sans-serif;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 0 1.5em;

      &__weekday {
        &,
        &--highlighted {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          background: #2c3e50;
          border: 0 solid white;
          border-left-width: 1px;
          padding: 0.1em 0.3em;
          width: 7em;

          &:first-child {
            border-radius: 3px 0 0 0;
            border-left-width: 0;
          }

          &:last-child {
            border-radius: 0 3px 0 0;
          }
        }

        &--highlighted {
          background: $site-red-color;
        }

        &__text {
          font-size: 0.94em;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
        }
      }
    }

    &__week-list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__week {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &__header {
        &,
        &--highlighted {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          background: #2c3e50;
          border: 0 solid white;
          border-top-width: 1px;
          font-size: 1em;
          padding: 0.4em 0;
          width: 1.5em;
        }

        &--highlighted {
          background: $site-red-color;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-size: 0.85em;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
          writing-mode: vertical-lr;
          text-orientation: sideways;
        }

        &__action-button {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-family: 'Open Sans', sans-serif;
          outline: none;
          cursor: pointer;
          -webkit-tap-highlight-color:  transparent;
          border: 1px solid white;
          border-radius: 3px;
          user-select: none;
          font-size: 0.9em;
          text-decoration: none;
          min-height: 32px;
          background: #e08283;
          color: white;
          pointer-events: auto;
          margin: 0.4em 0 0;
          width: 90%;

          &:hover:not(:disabled) {
            background: darken(#e7e7e7, 15);
          }

          &:disabled {
            background: #dcdcdc;
            color: #949494;
            cursor: default;
          }

          &:hover:not(:disabled) {
            background: darken(#e08283, 15);
          }
        }
      }

      &__content {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1;
        background: #cdcdcd;
        border: 0 solid $default-gray-color;
        border-top-width: 1px;
        border-radius: 0;
        overflow: hidden;
      }

      &:first-child &__content {
        border-top-width: 0;
      }

      &:last-child &__content {
        border-radius: 0 0 3px 0;
        border-bottom-width: 1px;
      }

      &__weekday {
        &__wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: stretch;
          width: 100%;
          padding: 0.2em;
          position: relative;
          pointer-events: none;
        }

        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        width: 7em;
        border: 0 solid $default-gray-color;
        border-left-width: 1px;
        position: relative;

        &:first-child {
          border-left-width: 0;
        }
        &:last-child {
          border-right-width: 1px;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          color: $default-text-color;
          font-weight: bold;
          width: 100%;
          font-size: 0.85em;
          white-space: pre-wrap;
          user-select: text;
          cursor: text;
        }

        &__controls {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          margin: 0 0 0.4em;
        }

        &__action-button {
          &,
          &--blue,
          &--red {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-family: 'Open Sans', sans-serif;
            // padding: 0.6em 0.8em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: 1px solid white;
            border-radius: 5px;
            user-select: none;
            font-size: 0.95em;
            text-decoration: none;
            min-width: 32px;
            min-height: 32px;
            color: #727272;
            background: #e7e7e7;
            pointer-events: auto;

            &:hover:not(:disabled) {
              background: darken(#e7e7e7, 15);
            }

            &:disabled {
              background: #dcdcdc;
              color: #949494;
              cursor: default;
            }

            &:not(:last-child) {
              margin: 0 0.4em 0 0;
            }
          }

          &--blue {
            background: #3598dc;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#3598dc, 15);
            }
          }
          &--red {
            background: #e08283;
            color: white;

            &:hover:not(:disabled) {
              background: darken(#e08283, 15);
            }
          }

          &__icon {
            pointer-events: none;
          }
        }

        &__grab-button {
          position: absolute;
          left: 0;
          top: 0;
          height: 30%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          outline: none;
          cursor: grab;
          -webkit-tap-highlight-color:  transparent;
          border: none;
          border-radius: 0 0 3px 0;
          user-select: none;
          font-size: 0.75em;
          text-decoration: none;
          color: #747474;
          background: #e7e7e7ad;
          width: 1.8em;
          pointer-events: auto;
        }
      }

      &:first-child &__header,
      &:first-child &__header--highlighted {
        border-radius: 3px 0 0 0;
        border-top-width: 0;
      }

      &:last-child  &__header,
      &:last-child  &__header--highlighted {
        border-radius: 0 0 0 3px;
      }
    }

    &__add-weekday-button,
    &__add-week-button {
      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: stretch;
        width: 100%;
      }

      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 0.6em 0.8em;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color:  transparent;
      border: 1px solid #959595;
      border-radius: 3px;
      user-select: none;
      font-size: 0.8em;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      margin: 0;
      background: #ffffff8f;
      text-transform: uppercase;
      font-weight: bold;
      color: #727272;
      width: 100%;
      margin: 0.5em;
      white-space: normal;
      // overflow-wrap: break-word;

      &:hover:not(:disabled) {
        background: $default-green-color;
        color: white;
        border-color: white;
      }

      &:disabled {
        background: #dcdcdc;
        color: #949494;
        cursor: default;
      }
    }

    &__add-week-button__icon {
      margin: 0 0.5em 0 0;
    }
  }

  &__support-button {
    cursor: pointer;

    &,
    &--active {
      position: relative;
      align-self: flex-end;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 1em 0 0;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      font-size: 1.3em;
      padding: 0.5em 1em;
      font-weight: 600;
      border-radius: 5px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      color: white;
      background: linear-gradient(17deg, $default-green-color, lighten($default-green-color, 12));
      border: 2px solid $background-color;

      @media (max-width: $very-small-width-breakpoint) {
        width: 100%;
      }
    }

    &--active {
      cursor: default;
    }

    &__icon {
      font-size: 1em;
      margin: 0 0.5em 0 0;

      @media (max-width: $tiny-width-breakpoint) {
        position: absolute;
        left: 1em;
      }
    }

    &--active,
    &:hover {
      color: $default-green-color;
      background: #f8f8f8;
      border-color: $default-green-color;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.1em;
    }

    &__text-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      @media (max-width: $tiny-width-breakpoint) {
        margin: 0 0 0 0.5em;
      }
    }

    &__note-text {
      font-size: 0.65em;
      font-weight: normal;
      margin: 0.2em 0 0;
    }
  }
}
