@import '../color_variables.scss';
@import '../size_variables.scss';

.main-menu {
  flex: 1;
  padding: 7em 5em 4em;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  // display: grid;
  // grid-template:
  //   "trainings trainings" 15vh
  //   "checkin checkin" 15vh
  //   "home_training home_training" 15vh
  //   "personal_training personal_training" 15vh
  //   "food_prescription food_prescription" 15vh
  //   / 1fr 1fr;
  // justify-items: stretch;
  // align-items: stretch;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 3.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 3.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint) {
    padding: 5em 1em 2em;
    // grid-template:
    //   "trainings" 15vh
    //   "checkin" 15vh
    //   "home_training" 15vh
    //   "personal_training" 15vh
    //   "food_prescription" 15vh
    //   / 1fr;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  // .checkin-menu-option {
  //   grid-area: checkin;
  // }
  //
  // .trainings-menu-option {
  //   grid-area: trainings;
  // }
  //
  // .home-training-menu-option {
  //   grid-area: home_training;
  // }
  //
  // .personal-training-menu-option {
  //   grid-area: personal_training;
  // }
  //
  // .food-prescription-menu-option {
  //   grid-area: food_prescription;
  // }

  &__link-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;

    &:not(:last-child) {
      margin: 0 0 1.5em;

      @media (max-width: $medium-width-breakpoint) {
        margin: 0 0 1.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 0 0 0.9em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        margin: 0 0 0.7em;
      }
    }
  }

  &__button {
    position: relative;
    padding: 0.4em 0;

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      padding: 0.6em 0;
    }

    &__icon {
      position: absolute;
      right: 0.5em;
      font-size: 0.85em;
    }
  }

  &__warning-pop-up .confirmation-window__cancel-button {
    margin: 0 0.7em 0 0 !important;
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    width: 100%;
    height: 90%;
    margin: 0;
    align-self: center;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }

        &--red {
          color: $site-red-color;
        }
      }
    }

    &__content-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      padding: 0.2em;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__loading-icon {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../images/preloader.gif), white;
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__message {
      &__text {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.1em;
        font-weight: bold;
        color: $default-black-color;
        margin: 0 0 0.5em;
        text-align: justify;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__button {
        font-size: 1.8em;
        padding: 0.8em 0.2em;
        flex-grow: 0;
        align-self: stretch;
        box-shadow: none;
        border-radius: 3px;
        margin: 0.5em 0 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.4em;
        }
      }
    }
  }

  &__alert-text {
    text-align: center;
    background: #f5f5f5;
    color: $site-red-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5em 0;
    margin: 0 0 0.5em;

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__fyd-form {
    &__question {
      &,
      &--required {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-self: stretch;
        border: 1px solid $default-black-color;
        padding: 0.4em;
        border-radius: 5px;
        transition: all 0.4s cubic-bezier(0.22, 0.97, 0.58, 1);

        &:not(:last-child) {
          margin: 0 0 1.2em;

          @media (max-width: $medium-less-width-breakpoint) {
            margin: 0 0 1em;
          }
          @media (max-width: $small-width-breakpoint) {
            margin: 0 0 0.8em;
          }
          @media (max-width: $very-small-width-breakpoint) {
            margin: 0 0 0.6em;
          }
        }
      }

      &--required {
        border-color: $site-red-color;
      }

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 0.2em;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.4em;
        }
      }

      &__label {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.1em;
        font-weight: bold;
        color: $default-black-color;
        margin: 0 0 0.5em;
        text-align: justify;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__answer-options {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-self: stretch;
        border-radius: 0.1em;

        &__item {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: stretch;
          list-style: none;
          flex: 1 1;

          &__button {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            padding: 0.4em 0.6em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            border: none;
            user-select: none;
            font-size: 1em;
            text-decoration: none;
            min-width: 35px;
            min-height: 35px;
            font-weight: bold;
            border: 1px solid $default-black-color;
            background: $default-black-color;
            flex: 1 1;

            @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
              border-right-width: 0;
            }

            &:disabled {
              cursor: default;
              color: white;
            }
          }

          &__value {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5em;

            @media (max-width: $small-width-breakpoint) {
              font-size: 1.2em;
            }

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 1em;
            }
          }
        }
      }

      &__long-text-input {
        align-self: stretch;
      }

      &__required-warning {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        padding: 0.4em 0.6em;
        background-color: $site-red-color;
        border-radius: 4px;
        font-size: 0.8em;
        margin: 0.3em 0 0;

        &__icon {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: white;
          margin: 0 0.4em 0 0;
          font-size: 1.4em;
        }

        &__text {
          font-family: 'Montserrat', sans-serif;
          color: white;
        }
      }
    }

    &__user-note {
      font-family: 'Montserrat', sans-serif;
      margin: 0 0 1em;
      text-align: justify;
      color: #974c4c;
      font-weight: bold;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
        margin: 0 0 0.7em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.85em;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 1px 0 0;
    margin: 1em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 0.5em 0;
    }
  }
}
