@import '../color_variables.scss';
@import '../size_variables.scss';


.personal-record {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  padding: 6em 3.5em 3.5em;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint) {
    padding: 5em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 4em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3.5em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__wrapper {
    max-width: 90em;
    flex: 1;
  }

  &__action-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 1.2em 0;

    @media (max-width: $medium-less-width-breakpoint) {
      margin: 1em 0;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0.7em 0;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 0.6em 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0.5em 0;
    }
  }

  &__exercise-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: $default-black-color;
    border-radius: 5px;
    border: 1px solid $default-black-color;
    border-top-width: 0;
    margin: 0 0 2em;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 3px;
    }

    &__header {
      // font-family: 'Black Ops One', cursive;
      font-family: 'Black Ops One', fantasy;
      color: white;
      font-size: 1.7em;
      padding: 0.3em 0.5em 0.2em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: white;
      padding: 0.4em;
      border-radius: 0 0 5px 5px;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.3em 0.2em;
      }
    }

    &__no-entry-message {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: center;
      font-size: 1.2em;
    }

    &__entry {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      font-family: 'Montserrat', sans-serif;
      border: 1px solid $default-black-color;
      border-radius: 3px;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.8em;
      }

      &:not(:first-child) {
        margin: 0.5em 0 0;
      }

      &__title {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2em;
        margin-left: 0.4em;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 1.1em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
      }

      &__action {
        font-size: 1.4em;
        border-radius: 3px;
        box-shadow: none;
        padding: 0.4em;
        flex: none;
        margin: 0.2em 0;
        width: 2em;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.3em;
        }

        &:not(:first-child) {
          margin-left: 0.5em;

          @media (max-width: $smaller-width-breakpoint) {
            margin-left: 0.4em;
          }
        }

        &:last-child {
          margin-right: 0.2em;
        }
      }
    }
  }

  &__external-text-link {
    background: #a75050;
    border-radius: 3px;
    padding: 0.2em 0.5em;
    margin: 0 0.4em;
    text-transform: uppercase;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__unit-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.1em;
      border-radius: 2px;
      padding: 0.7em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &__warning-message {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    align-self: center;
    margin: 0;
    background: white;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;

    @media (max-width: $large-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.85em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.75em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }

    &__text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 1.1em;
      font-weight: 600;
      flex: 1;
      color: #a75050;
      padding: 0.5em 1em;
      line-height: 1.8em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: #a75050;
      color: white;
      padding: 0.8em;
      border-radius: 5px 0 0 5px;
      font-size: 1.8em;
    }
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }

    &__red-text {
      color: #a75050;
    }
  }

  &__unit-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &__title {
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }
      }

      &__toggle-button {
        flex: none;
        font-size: 1.2em;
        padding: 0.5em;
        box-shadow: none;
        border-radius: 3px;
        margin: 0 0 0 0.5em;
      }
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__reference-wrapper {
      border: 1px solid $default-black-color;
      border-radius: 3px;
      padding: 0.6em 0.6em 0.4em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0.2em;
      }

      &:not(:last-of-type) {
        margin: 0 0 0.8em;
      }
    }

    &__reference-content {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &__reference {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      // border: 0 solid $default-black-color;
      // border-bottom-width: 1px;
      padding: 0 0 0.2em;
      min-width: 48em;

      &:not(:last-of-type) {
        margin: 0 0 0.8em;
      }

      &__date-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.8em;
        font-weight: bold;
        color: #8d8d8d;
        padding: 0.2em 0 0;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        margin: 0 2em 0 0;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__input {
        &,
        &--extended {
          border: 0 solid white;
          border-width: 0 1px;
        }

        & input,
        &--extended input {
          font-weight: bold;
          font-size: 1.1em;
          padding: 0.3em 0.2em !important;
          flex-shrink: 0;
          border: 0 solid white;
        }

        & input {
          width: 4em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        &--extended input {
          width: 4.5em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        & .default-input__input-suffix,
        &--extended .default-input__input-suffix {
          border-radius: 0;
          border: 0 solid white;
        }
      }

      &__input-prefix {
        &--highlighted {
          font-weight: bold;
        }
      }

      &__input-container {
        &,
        &--selected {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: center;
          flex: 1;

          // &:not(:first-child) {
          //   margin: 0 0 0 0.5em;
          // }

          &--horizontal {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      &__pr-input-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
      }

      &__pr-text {
        color: $default-black-color;
      }

      &__arrow-icon {
        font-size: 1.4em;
        margin: 0 0.5em;
        color: $default-black-color;
      }

      &__generic-text {
        text-align: center;
        color: #7d7d7d;
        margin: 0.2em 0 0;
      }

      &__input-group-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;

        &:first-child:last-child &__title-wrapper--resistance,
        &:first-child:last-child &__title-wrapper--hypertrophy,
        &:first-child:last-child &__title-wrapper--strength {
           border-radius: 3px 3px 0 0;
        }
        &:first-child:not(:last-child) &__title-wrapper--resistance,
        &:first-child:not(:last-child) &__title-wrapper--hypertrophy,
        &:first-child:not(:last-child) &__title-wrapper--strength {
          border-radius: 3px 0 0 0;
        }
        &:not(:first-child):last-child &__title-wrapper--resistance,
        &:not(:first-child):last-child &__title-wrapper--hypertrophy,
        &:not(:first-child):last-child &__title-wrapper--strength {
          border-radius: 0 3px 0 0;
        }

        &__title-wrapper {
          &,
          &--resistance,
          &--hypertrophy,
          &--strength {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-weight: bold;
            text-shadow: 1px 1px white;
            padding: 0.3em;
            border: 1px solid white;
            border-bottom-width: 0;
          }

          &--resistance {
            background: linear-gradient(348deg, #b7b34c, #fff605, #b7b34c);
            color: #3c3a02;
          }
          &--hypertrophy {
            background: linear-gradient(348deg, #a78425, #ffbc00, #a78425);
            color: #584203;
          }
          &--strength {
            background: linear-gradient(348deg, #ad5b22, #ff6a00, #ad5b22);
            color: #6a2f06;
          }
        }

        &__title {
          font-size: 0.9em;
        }
      }

      &__input-groups-wrapper,
      &__percentages-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__pr-input-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: #c7c7c7;
        padding: 0.4em 0.4em;
        // border-radius: 3px 3px 0 0;
        border: 0 solid white; //#c2cad8
        border-width: 0 1px;
      }

      &__input-container--selected &__pr-input-header {
        background: #7bd053;
        border-color: #45a01a;
      }
    }
  }

  &__action-button {
    font-size: 1.5em;
    padding: 0.5em;
    align-self: stretch;
    margin: 0 0.5em 0 0;
    border-radius: 3px;
    box-shadow: none;
    min-width: auto;
    flex: 0;
  }
}
