@import '../size_variables.scss';
@import '../color_variables.scss';
@import '../constants.scss';

$default-black-color: #3a3839;

// $scale-reference-1: #006837;
// $scale-reference-2: #1a9850;
// $scale-reference-3: #66bd63;
// $scale-reference-4: #a6d96a;
// $scale-reference-5: #d9ef8b;
// $scale-reference-6: #fee08b;
// $scale-reference-7: #fdae61;
// $scale-reference-8: #f46d43;
// $scale-reference-9: #d73027;
// $scale-reference-10: #a50026;
$scale-reference-1: #a50026;
$scale-reference-2: #d73027;
$scale-reference-3: #f46d43;
$scale-reference-4: #fdae61;
$scale-reference-5: #fee08b;
$scale-reference-6: #d9ef8b;
$scale-reference-7: #a6d96a;
$scale-reference-8: #66bd63;
$scale-reference-9: #1a9850;
$scale-reference-10: #006837;


.scale-picker {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  align-self: stretch;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2c3e50;
    border-radius: 0.4em;
  }

  &__group {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    list-style: none;
    flex: 1 1;

    &__image {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        font-size: 3em;
        padding: 0.2em 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 2.7em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 2.5em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.8em;
        }
      }
    }

    &__image,
    &__description {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        border: 0 solid white;
        border-right-width: 1px;
        border-left-width: 1px;
        background: $default-black-color;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
          border-right-width: 0;
        }
      }

      &--1 {
        color: $scale-reference-1;
      }
      &--2 {
        color: $scale-reference-2;
      }
      &--3 {
        color: $scale-reference-3;
      }
      &--4 {
        color: $scale-reference-4;
      }
      &--5 {
        color: $scale-reference-5;
      }
      &--6 {
        color: $scale-reference-6;
      }
      &--7 {
        color: $scale-reference-7;
      }
      &--8 {
        color: $scale-reference-8;
      }
      &--9 {
        color: $scale-reference-9;
      }
      &--10 {
        color: $scale-reference-10;
      }
    }

    &:first-of-type &__image {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        border-radius: 0.1em 0 0 0;
        border-left-width: 0;
      }
    }
    &:last-of-type &__image {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        border-radius: 0 0.1em 0 0;
        border-right-width: 0;
      }
    }

    &:first-of-type &__description {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        border-radius: 0 0 0 0.1em;
        border-left-width: 0;
      }
    }
    &:last-of-type &__description {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        border-radius: 0 0 0.1em 0;
        border-right-width: 0;
      }
    }

    &__description {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        text-transform: uppercase;
        font-size: 1.1em;
        font-weight: bold;
        padding: 0.5em 0;
        min-width: 9em;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.8em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.8em;
        }
      }

      &__text {
        font-size: 1em;
      }
    }

    &__options {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;
      flex: 1;
    }

    &__option {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8,
      &--9,
      &--10 {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.4em 0.6em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        font-size: 1em;
        text-decoration: none;
        min-width: 35px;
        // min-height: 35px;
        min-width: 2.7em;
        font-weight: bold;
        border: 1px solid $default-black-color;
        background: $default-black-color;
        flex: 1 1;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
          border-right-width: 0;
        }

        &:disabled {
          cursor: default;
          color: white;
        }
      }

      &--1 {
        color: $scale-reference-1;
        border-color: $scale-reference-1;

        &:disabled {
          background: $scale-reference-1;
        }
      }
      &--2 {
        color: $scale-reference-2;
        border-color: $scale-reference-2;

        &:disabled {
          background: $scale-reference-2;
        }
      }
      &--3 {
        color: $scale-reference-3;
        border-color: $scale-reference-3;

        &:disabled {
          background: $scale-reference-3;
        }
      }
      &--4 {
        color: $scale-reference-4;
        border-color: $scale-reference-4;

        &:disabled {
          background: $scale-reference-4;
        }
      }
      &--5 {
        color: $scale-reference-5;
        border-color: $scale-reference-5;

        &:disabled {
          background: $scale-reference-5;
        }
      }
      &--6 {
        color: $scale-reference-6;
        border-color: $scale-reference-6;

        &:disabled {
          background: $scale-reference-6;
        }
      }
      &--7 {
        color: $scale-reference-7;
        border-color: $scale-reference-7;

        &:disabled {
          background: $scale-reference-7;
        }
      }
      &--8 {
        color: $scale-reference-8;
        border-color: $scale-reference-8;

        &:disabled {
          background: $scale-reference-8;
        }
      }
      &--9 {
        color: $scale-reference-9;
        border-color: $scale-reference-9;

        &:disabled {
          background: $scale-reference-9;
        }
      }
      &--10 {
        color: $scale-reference-10;
        border-color: $scale-reference-10;

        &:disabled {
          background: $scale-reference-10;
        }
      }
    }

    &__option-number,
    &__option-text {
      &,
      &--flex {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        text-shadow: 0 0 2px $default-black-color;
      }

      &--flex {
        flex: 1;
      }
    }

    &__option-number {
      &,
      &--flex {
        font-size: 1.5em;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }
      }
    }

    &__option-text {
      &,
      &--flex {
        font-size: 0.9em;

        @media (max-width: $large-width-breakpoint) {
          font-size: 0.6em;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          display: none;
        }
      }
    }
  }

  &__small-note {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: justify;
    margin: 0.2em 0 0;
    font-size: 0.8em;
    font-weight: bold;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }
  }
}
